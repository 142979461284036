import React, { useEffect, useState } from "react";
import { match } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";

import IRouterComponentProps from "../../models/IRouterComponentProps";
import DetailsCarousel, {
  StyledGrid,
  StyledNotAvailableImage,
  StyledProductImage,
} from "./details/DetailsCarousel";
import AuthService from "../auth/AuthService";
import { getProductDetailUrl } from "../../common/config/app.endpoints";
import LoadingSpinner from "../../common/elements/LoadingSpinner";
import ApiTimeout from "../../modules/ApiTimeout";
import { sideMenuGradient } from "../../common/materials/LinearGradient";
import JewelleryPerItemDescription from "./details/JewelleryPerItemDescription";
import ProductDescription from "../shared/ProductDescription";
import {
  StyledZipangLogoImage,
  StyledZipangLogoWrapper,
} from "./ShareProductDetailsView";

const StyledLayoutGrid = styled(Grid)`
  flexgrow: 1;
  width: 100% !important;
  display: flex-wrap;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  background: ${(props) => props.theme.colors.base.white};
`;

const StyledTitleContainer = styled(Grid)`
  padding: 20px 0;
  ${(props) => props.theme.breakpoints.down("xs")} {
    padding: 15px 10px 10px;
  }
`;

const StyledBrandName = styled.div`
  color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  font-size: 1.5em;
  font-weight: bold;
`;

const StyledProductCarousalColumn = styled(Grid)`
  padding-right: 20px;
  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: 0px;
  }
`;

const StyledItemDesc = styled(Grid)`
  border: 1px solid
    ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  font-weight: bold;
  color: ${(props) => props.theme.colors.base.grey};
  padding: 8px;
  border-radius: 3px;
  font-size: 0.8em;
  margin-top: 10px;
  flex-direction: row;
  display: flex;
`;

const StyledGridWrapper = styled(Grid)`
  display: flex;
  border-bottom: 1px solid
    ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  padding: 10px;
`;

const StyledContentWrapper = styled(Grid)`
  padding-top: 20px;
`;

interface IButtonProps {
  isSubmit?: boolean;
}

const StyledHeaderContainer = styled(Grid)`
  padding: 0 !important;
  background: ${(props) => props.theme.colors.base.darkBlue};
  background: ${sideMenuGradient};
  height: 66px;
`;

const StyledWrapperTop = styled(Grid)`
  display: flex;
`;

const StyledResetPaddingContainer = styled(Grid)`
  background: ${(props) => props.theme.colors.base.transparent};
  justify-content: center;
  align-items: center;
`;

const StyledLogoWrapper = styled.div`
  display: inline-block;
  width: 100%;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    img {
      width: 20%;
    }
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    img {
      width: 20%;
    }
  }
  ${(props) => props.theme.breakpoints.between("lg", "xl")} {
    img {
      width: 20%;
    }
  }
  display: flex;
  margin-left: 15px;
  margin-top: 15px;
`;

const StyledLogoImage = styled.img`
  height: 2.5em;
  width: 1.5em;
`;

const StyledTextContainer = styled.div`
  background: ${(props) => props.theme.colors.base.transparent};
  padding: 5px 5px;
  color: ${(props) => props.theme.colors.base.white};
  font-size: 1.5em;
  font-family: SF-Display-Medium;
`;

const StyledSorryTextWrapper = styled(Grid)`
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 30px;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    font-size: 70px;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    font-size: 100px;
  }
  font-family: Calibre-Regular;
  color: ${(props) => props.theme.colors.base.darkGrey};
`;

const StyledTextWrapper = styled(Grid)`
  font-size: 30px;
  font-family: Calibre-Regular;
  color: ${(props) => props.theme.colors.base.grey};
`;

const StyledOOPsContainer = styled.div`
  text-align: center;
  width: 100%;
`;

const StyledWrapperID = styled(Grid)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledProductName = styled.div`
  color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  font-size: 1em;
`;

const StyledArrowIconsID = styled.img`
  width: 1.5em;
  height: 1.5em;
  cursor: pointer;
  margin-left: 15px;
  margin-top: 10px;
  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    margin-right: 10px;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    margin-right: 5px;
  }
`;

interface DetailParams {
  auctionId: string;
  productId: string;
  auctionDate: string;
}
let auctionDate: string = "";

export interface IProps extends IRouterComponentProps {
  t?: any;
  match: match<DetailParams>;
  location: any;
}

let fullData: any = [""];
let items: any = [];
let jewelleryDetails: any;
let jewelleryType: string | null = "";
let jewelleryCondition: string | null = "";
let jewelleryDescription: string | null = "";

const ShareJewellerylink = (props: IProps) => {
  const [item, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isProductAvailable, setIsProductAvailable] = useState(false);
  const [apiTimeout, setApiTimeout] = useState(false);
  const [count, setCount] = useState(0);
  const [isPDView, setIsPDView] = useState(false);
  const [userBlock, setUserBlock] = useState(false);
  const [openToast, setOpenToast] = useState(false);

  useEffect(() => {
    setIsLoading(false);
    if (props.match.params.auctionDate !== undefined) {
      auctionDate = props.match.params.auctionDate;
    }
    getDetails(props.match.params.productId);
  }, []);

  const { t } = props;
  const Auth = new AuthService();

  const getDetails = (productId: string | number) => {
    setOpenToast(false);
    setIsLoading(true);
    Auth.getRequest(
      `${getProductDetailUrl}/jewellery/${productId}/detail`,
      (status: number, response: any) => {
        if (status === 200) {
          if (response.subItems) {
            var rows = [];
            for (var i = 0; i < response.subItems.length; i++) {
              let obj = response.subItems[i];
              if (obj.medias !== undefined) {
                for (var j = 0; j < obj.medias.length; j++) {
                  rows.push(obj.medias[j]);
                }
              }
            }
            var objModify = response;
            objModify.medias = objModify.medias.concat(rows);
            setOpenToast(true);
            items = objModify;
            fullData = response;
            setIsLoading(false);
            setIsProductAvailable(
              response.state === "Available" ? true : false
            );
          } else {
            items = response;
            fullData = response;
            setIsLoading(false);
            setIsProductAvailable(
              response.state === "Available" ? true : false
            );
          }
        } else if (status === 403) {
          setUserBlock(true);
        } else if (status === 505) {
          setApiTimeout(true);
          setIsLoading(false);
        }
      }
    );
  };

  if (
    jewelleryDetails !== undefined &&
    jewelleryDetails !== null &&
    jewelleryDetails !== ""
  ) {
    if (
      jewelleryDetails.jewelleryType !== undefined &&
      jewelleryDetails.jewelleryType !== null
    ) {
      jewelleryType = jewelleryDetails.jewelleryType;
    }

    if (
      jewelleryDetails.jewelleryCondition !== undefined &&
      jewelleryDetails.jewelleryCondition !== null
    ) {
      jewelleryCondition = jewelleryDetails.jewelleryCondition;
    }

    if (
      jewelleryDetails.jewelleryDescription !== undefined &&
      jewelleryDetails.jewelleryDescription !== null
    ) {
      jewelleryDescription = jewelleryDetails.jewelleryDescription;
    }
  }

  const nextButtonSubItem = () => {
    if (fullData.subItems.length > count) {
      setItems(fullData.subItems[count]);
      setCount(count + 1);
    }
  };

  const previousButtonSubItem = () => {
    if (fullData.subItems.length === count) {
      setItems(fullData.subItems[count - 2]);
      setCount(count - 1);
    } else if (count === 1) {
      setItems(fullData);
      setCount(count - 1);
    } else {
      if (count > 0) {
        setItems(fullData.subItems[count - 2]);
        setCount(count - 1);
      }
    }
  };
  const onPressSign = (
    auctionId: string,
    perId: string,
    auctionDate: string
  ) => {
    let auctioonuserid = "";
    var joinedAuctions: any = localStorage.getItem("joinedAnnualAuctions");
    joinedAuctions = JSON.parse(joinedAuctions);
    if (joinedAuctions !== null) {
      joinedAuctions.filter((items: any) => {
        if (items.category === "Jewellery") {
          auctioonuserid = items.auctionUserId;
        }
      });
    }
    let languageCode =
      localStorage.getItem("language") === "EN"
        ? "en"
        : localStorage.getItem("language") === "zh_CN"
        ? "zh_CN"
        : "jp";
    if (fullData.auctionType === "Popcorn_Bidding") {
      window.open(
        `/${languageCode}/preview/tender/jewellery/details/${auctionId}/${perId}/${auctionDate}`,
        "_blank"
      );
    } else if (fullData.auctionType === "Annual") {
      window.open(
        `/365products/details/${auctionId}/${auctioonuserid}/${perId}/${auctionDate}/${false}?auctionType=Jewellery`,
        "_blank"
      );
    } else {
      window.open(
        `/${languageCode}/preview/jewelleryPreview/details/${auctionId}/${perId}/${auctionDate}`,
        "_blank"
      );
    }
  };
  return (
    <Grid>
      {apiTimeout === true ? (
        <ApiTimeout t={t} {...props} />
      ) : (
        <Grid>
          {isLoading === true ? (
            <div className="loader">
              <LoadingSpinner />
            </div>
          ) : (
            [
              isProductAvailable ? (
                <StyledLayoutGrid container={true} spacing={0}>
                  <StyledHeaderContainer
                    item={true}
                    xs={12}
                    className="headerContainer"
                  >
                    <StyledWrapperTop container={true} spacing={0}>
                      <StyledResetPaddingContainer item xs={12} sm={6} lg={3}>
                        {localStorage.getItem("subDomain") === "zipang" ? (
                          <StyledZipangLogoWrapper>
                            <StyledZipangLogoImage src="https://ds29nc3ljdjce.cloudfront.net/zipang_logo_normal.png" />
                          </StyledZipangLogoWrapper>
                        ) : (
                          <>
                            {localStorage.getItem("subDomain") ===
                            "isGlowstarDomain" ? (
                              <StyledZipangLogoWrapper>
                                <StyledZipangLogoImage src="https://ds29nc3ljdjce.cloudfront.net/icon.png" />
                              </StyledZipangLogoWrapper>
                            ) : (
                              <StyledLogoWrapper>
                                <StyledLogoImage src="/images/logo.png" />
                                <StyledTextContainer>
                                  {t("common:header:logoText")}
                                </StyledTextContainer>
                              </StyledLogoWrapper>
                            )}
                          </>
                        )}
                      </StyledResetPaddingContainer>
                    </StyledWrapperTop>
                  </StyledHeaderContainer>
                  <StyledGridWrapper
                    item={true}
                    xs={12}
                    xl={12}
                    lg={12}
                    md={12}
                  >
                    <Grid container={true} spacing={0}>
                      <StyledTitleContainer
                        item={true}
                        xs={12}
                        sm={12}
                        xl={9}
                        lg={8}
                        md={12}
                      >
                        <StyledBrandName>
                          <StyledProductName>
                            <ProductDescription
                              truncateLength={50}
                              data={items}
                              isTendor={false}
                            />
                          </StyledProductName>
                        </StyledBrandName>
                      </StyledTitleContainer>
                    </Grid>
                  </StyledGridWrapper>
                  <Grid item={true} xs={12}>
                    <StyledContentWrapper container={true} spacing={0}>
                      <StyledProductCarousalColumn
                        item={true}
                        xs={12}
                        sm={6}
                        md={6}
                        lg={5}
                        xl={4}
                      >
                        {items.medias !== undefined &&
                        items.medias.length > 0 ? (
                          <DetailsCarousel {...items} t={t} />
                        ) : (
                          <StyledGrid container={true} spacing={0}>
                            <StyledNotAvailableImage>
                              <StyledProductImage src="/images/default-diamond.png"></StyledProductImage>
                            </StyledNotAvailableImage>
                          </StyledGrid>
                        )}
                      </StyledProductCarousalColumn>
                      <Grid item={true} xs={12} sm={6} md={6} lg={7} xl={8}>
                        <Grid item={true} xs={12}>
                          <StyledWrapperID item={true} xs={12}>
                            <StyledItemDesc item={true} xs={12}>
                              {t(
                                "common:preview:productDetail:itemDescription"
                              )}
                              {": "}
                              <StyledProductName>
                                <ProductDescription
                                  truncateLength={90}
                                  data={items}
                                  isTendor={false}
                                />
                              </StyledProductName>
                            </StyledItemDesc>
                            {fullData.subItems != null && (
                              <>
                                {count === 0 ? (
                                  <StyledArrowIconsID
                                    onClick={previousButtonSubItem}
                                    src="/images/controls/left_arrow_round_edges_disabled.png"
                                  />
                                ) : (
                                  <StyledArrowIconsID
                                    onClick={previousButtonSubItem}
                                    src="/images/controls/left_arrow_round_edges.png"
                                  />
                                )}
                                {fullData.subItems.length === count ? (
                                  <StyledArrowIconsID
                                    onClick={nextButtonSubItem}
                                    src="/images/controls/right_arrow_round_edges_disabled.png"
                                  />
                                ) : (
                                  <StyledArrowIconsID
                                    onClick={nextButtonSubItem}
                                    src="/images/controls/right_arrow_round_edges.png"
                                  />
                                )}
                              </>
                            )}
                          </StyledWrapperID>
                          {items !== "" && (
                            <JewelleryPerItemDescription
                              t={t}
                              {...items}
                              auctionDate={auctionDate}
                              isPDView={isPDView}
                              onClickSignIn={() =>
                                onPressSign(
                                  fullData.auctionId,
                                  fullData.perId,
                                  auctionDate
                                )
                              }
                            />
                          )}
                        </Grid>
                      </Grid>
                    </StyledContentWrapper>
                  </Grid>
                </StyledLayoutGrid>
              ) : (
                <StyledLayoutGrid container={true} spacing={0}>
                  <StyledHeaderContainer
                    item={true}
                    xs={12}
                    className="headerContainer"
                  >
                    <StyledWrapperTop container={true} spacing={0}>
                      {/* <StyledResetPaddingContainer item xs={3} sm={6} lg={3}>
                        <StyledLogoWrapper>
                          <StyledLogoImage src="/images/logo.png" />
                          <StyledTextContainer>
                            {t("common:header:logoText")}
                          </StyledTextContainer>
                        </StyledLogoWrapper>
                      </StyledResetPaddingContainer> */}
                    </StyledWrapperTop>
                  </StyledHeaderContainer>
                  <Grid container={true} spacing={0}>
                    <StyledOOPsContainer>
                      <StyledSorryTextWrapper>
                        {t("common:pageNotFound:oops")}
                      </StyledSorryTextWrapper>
                      <StyledTextWrapper>
                        {t(
                          "common:preview:perItemDescription:noProductMessage"
                        )}
                      </StyledTextWrapper>
                    </StyledOOPsContainer>
                  </Grid>
                </StyledLayoutGrid>
              ),
            ]
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default ShareJewellerylink;
