import React from "react";
import { Grid, Typography } from "@mui/material";
import styled from "styled-components";
import BoxSlider from "./Box-slider/Our-page-box-slider";
import FlexDivider from "./Box-slider/PriceValues";
import { useTranslation } from "react-i18next";

const StyledBoxContainer = styled(Grid)`
  padding: 4em;
  color: black;
  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    padding: 1em;
  }
`;

const StyledGrid = styled(Grid)`
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    padding: 2px 2px;
  }
  ${(props) => props.theme.breakpoints.between("sm", "xl")} {
    padding: 20px 20px;
  }
`;

let sellerData = [
  {
    title: "common:zipangLanding:ourTeam:seller:tile1:heading",
    link: "https://ds29nc3ljdjce.cloudfront.net/zipang/languageSelect.png",
    subHeading1: "common:zipangLanding:ourTeam:seller:tile1:description",
    isLink: true,
  },
  {
    title: "common:zipangLanding:ourTeam:seller:tile2:heading",
    link: "/images/seller/seller2.png",
    subHeading1: "common:zipangLanding:ourTeam:seller:tile2:description",
    isLink: true,
  },
  {
    title: "common:zipangLanding:ourTeam:seller:tile3:heading",
    link: "/images/seller/seller3.png",
    subHeading1: "common:zipangLanding:ourTeam:seller:tile3:description",
    isLink: true,
  },
  {
    title: "common:zipangLanding:ourTeam:seller:tile4:heading",
    link: "icon",
    subHeading1: "common:zipangLanding:ourTeam:seller:tile4:description",
    isLink: false,
  },
];
let BuyerData = [
  {
    title: "common:zipangLanding:ourTeam:buyer:tile1:heading",
    link: "/images/seller/buyer1.png",
    subHeading1: "common:zipangLanding:ourTeam:seller:tile1:description",
    isLink: true,
  },
  {
    title: "common:zipangLanding:ourTeam:buyer:tile2:heading",
    link: "icon",
    subHeading1: "common:zipangLanding:ourTeam:seller:tile2:description",
    isLink: false,
  },
  {
    title: "common:zipangLanding:ourTeam:buyer:tile3:heading",
    link: "/images/seller/buyer3.png",
    subHeading1: "common:zipangLanding:ourTeam:seller:tile3:description",
    isLink: true,
  },
  {
    title: "common:zipangLanding:ourTeam:buyer:tile4:heading",
    link: "/images/seller/buyer4.png",
    subHeading1: "common:zipangLanding:ourTeam:seller:tile4:description",
    isLink: true,
  },
];
function WhyChooseUsSection() {
  let { t } = useTranslation();
  return (
    <>
      {" "}
      <Grid
        container
        style={{
          marginTop: "3em",
          marginBottom: "2em",
          fontFamily: "Playfair Display",
          background:
            "transparent linear- gradient(180deg, #F3F3F3 0 %, #FFFFFF 100 %) 0% 0% no-repeat padding-box",
        }}
      >
        <div
          style={{
            color: "black",
            textAlign: "left",
            alignItems: "center",
            justifyContent: "left",
            width: "100%",
            display: "flex",
            marginBottom: "1em",
            marginLeft: "5%",
          }}
        >
          <Typography
            variant="h4"
            fontWeight={700}
            style={{ fontFamily: "Playfair Display" }}
          >
            {t("common:zipangLanding:ourTeam:whyChooseus")}
          </Typography>
        </div>

        <div
          style={{
            color: "black",
            textAlign: "left",
            alignItems: "center",
            justifyContent: "left",
            width: "100%",
            display: "flex",
            marginBottom: "1em",
            marginLeft: "5%",
          }}
        >
          <Typography
            variant="h5"
            fontWeight={600}
            color={"#D92B3C"}
            style={{ fontFamily: "Playfair Display" }}
          >
            {t("common:zipangLanding:ourTeam:seemLessHeading")}
          </Typography>
        </div>

        <div
          style={{
            color: "black",
            textAlign: "left",
            alignItems: "center",
            justifyContent: "left",
            width: "100%",
            display: "flex",
            marginBottom: "1em",
            marginLeft: "5%",
          }}
        >
          <Typography
            variant="h5"
            fontWeight={600}
            color={"#D92B3C"}
            style={{ fontFamily: "Playfair Display" }}
          >
            {t("common:zipangLanding:heading:seller")}
          </Typography>
        </div>
        <StyledBoxContainer
          container
          xs={12}
          sx={{
            py: 0,
          }}
        >
          <StyledGrid item={true} xs={12} md={12} lg={12} xl={12}>
            <BoxSlider data={sellerData} />
          </StyledGrid>
        </StyledBoxContainer>

        <StyledBoxContainer
          container
          xs={12}
          sx={{
            py: 0,
          }}
        >
          <StyledGrid
            item={true}
            xs={12}
            md={12}
            lg={12}
            xl={12}
            style={{
              display: "flex",
              gap: 5,
              justifyContent: "space-evenly",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <FlexDivider isSeller={true} />
          </StyledGrid>
        </StyledBoxContainer>

        <div
          style={{
            color: "black",
            textAlign: "left",
            alignItems: "center",
            justifyContent: "left",
            width: "100%",
            display: "flex",
            marginBottom: "1em",
            marginLeft: "5%",
          }}
        >
          <Typography
            variant="h5"
            fontWeight={600}
            color={"#D92B3C"}
            style={{ fontFamily: "Playfair Display" }}
          >
            {t("common:zipangLanding:heading:buyer")}
          </Typography>
        </div>
        <StyledBoxContainer
          container
          xs={12}
          sx={{
            py: 0,
          }}
        >
          <StyledGrid item={true} xs={12} md={12} lg={12} xl={12}>
            <BoxSlider data={BuyerData} />
          </StyledGrid>
        </StyledBoxContainer>
        {/* <StyledBoxContainer
          container
          xs={12}
          sx={{
            py: 0,
          }}
        >
          <StyledGrid
            container={true}
            xs={12}
            md={12}
            lg={12}
            xl={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <FlexDivider isSeller={false} />
          </StyledGrid>
        </StyledBoxContainer> */}
      </Grid>
    </>
  );
}

export default WhyChooseUsSection;
