import React, { useRef, useState } from "react";
import styled from "styled-components";
import HomeIcon from "@material-ui/icons/Home";
import GavelIcon from "@material-ui/icons/Gavel";
import SearchIcon from "@material-ui/icons/Search";
import { StyledModal } from "../materials/GenericStyleComponent";
import ReactPlayer from "react-player";
import VisibilityIcon from "@material-ui/icons/Visibility";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import UpdateIcon from "@material-ui/icons/Update";
import AllInclusiveIcon from "@material-ui/icons/AllInclusive";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import PersonIcon from "@material-ui/icons/Person";
import SpeakerNotesIcon from "@material-ui/icons/SpeakerNotes";
import PermContactCalendarIcon from "@material-ui/icons/PermContactCalendar";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import "../../static/css/rightTabCss.css";

const RightTabsWrapper = styled.div`
  position: fixed;
  overflow: hidden;
  width: 100%;
  z-index: 999;
  height: 90vh;
  left: 0;
  top: 15%;
  pointer-events: none;
  ${(props) => props.theme.breakpoints.between("xs", "lg")} {
    top: 10%;
  }
`;

interface TabProps {
  isHovered?: boolean;
}

const PlayerWrapper = styled.div`
  border-radius: 10px;
  position: relative;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    width: 90%;
    height: 60%;
  }
  ${(props) => props.theme.breakpoints.between("md", "lg")} {
    width: 500px;
    height: 60%;
  }
  ${(props) => props.theme.breakpoints.between("lg", "xl")} {
    height: 60%;
    width: 50%;
  }
`;

const reactPlayer = {
  position: "absolute",
  top: 0,
  left: 0,
  borderRadius: 10,
  padding: 5,
  background: "white",
} as React.CSSProperties;

export default function RightTabs() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  let videoLinkRef = useRef("");

  const videoHandler = (index: number) => {
    switch (index) {
      case 0:
        videoLinkRef.current =
          "https://ds29nc3ljdjce.cloudfront.net/home_page.mp4";
        break;
      case 1:
        videoLinkRef.current =
          "https://ds29nc3ljdjce.cloudfront.net/scheduling_appointment.mp4";
        break;
      case 2:
        videoLinkRef.current =
          "https://ds29nc3ljdjce.cloudfront.net/zipang/preview.mp4";
        break;
      case 3:
        videoLinkRef.current =
          "https://ds29nc3ljdjce.cloudfront.net/zipang/search.mp4";
        break;
      case 4:
        videoLinkRef.current =
          "https://ds29nc3ljdjce.cloudfront.net/zipang/preBiddingAndBulkPreBidding.mp4";
        break;
      case 5:
        videoLinkRef.current =
          "https://ds29nc3ljdjce.cloudfront.net/zipang/productDetailPage.mp4";
        break;
      case 6:
        videoLinkRef.current =
          "https://ds29nc3ljdjce.cloudfront.net/support.mp4";
        break;
      case 7:
        videoLinkRef.current =
          "https://ds29nc3ljdjce.cloudfront.net/my_page.mp4";
        break;
      case 8:
        videoLinkRef.current =
          "https://ds29nc3ljdjce.cloudfront.net/auction_bidding_live.mp4";
        break;
      case 9:
        videoLinkRef.current =
          "https://ds29nc3ljdjce.cloudfront.net/zipang/popcornBidding.mp4";
        break;
      case 10:
        videoLinkRef.current =
          "https://ds29nc3ljdjce.cloudfront.net/zipang/365daysAuction.mp4";
        break;
      case 11:
        videoLinkRef.current =
          "https://ds29nc3ljdjce.cloudfront.net/zipang/invoice.mp4";
        break;
      case 12:
        videoLinkRef.current =
          "https://ds29nc3ljdjce.cloudfront.net/profile.mp4";
        break;
      default:
        break;
    }

    if (videoLinkRef.current !== "") {
      setIsModalOpen(true);
    } else {
      setIsModalOpen(false);
    }
  };
  const renderIframeVideo = () => {
    return (
      <StyledModal
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          videoLinkRef.current = "";
        }}
        className="video"
      >
        <PlayerWrapper>
          <ReactPlayer
            url={videoLinkRef.current}
            controls={true}
            style={reactPlayer}
            width="100%"
            height="100%"
          />
        </PlayerWrapper>
      </StyledModal>
    );
  };
  return (
    <RightTabsWrapper>
      <div id="floaty-container">
        <div className="floaty">
          <div className="floaty-link-box">
            <div className="floaty-links-icon">
              <HomeIcon
                className={"tabIcon"}
                style={{ color: "white", fontSize: 22 }}
              />
            </div>
            <div className="floaty-links-text" onClick={() => videoHandler(0)}>
              Home
            </div>
          </div>

          <div className="floaty-link-box">
            <div className="floaty-links-icon">
              <PermContactCalendarIcon
                className={"tabIcon"}
                style={{ color: "white", fontSize: 22 }}
              />
            </div>
            <div className="floaty-links-text" onClick={() => videoHandler(1)}>
              Request Appointment
            </div>
          </div>

          <div className="floaty-link-box">
            <div className="floaty-links-icon">
              <VisibilityIcon
                className={"tabIcon"}
                style={{ color: "white", fontSize: 22 }}
              />
            </div>
            <div className="floaty-links-text" onClick={() => videoHandler(2)}>
              Preview
            </div>
          </div>

          <div className="floaty-link-box">
            <div className="floaty-links-icon">
              <SearchIcon
                className={"tabIcon"}
                style={{ color: "white", fontSize: 22 }}
              />
            </div>
            <div className="floaty-links-text" onClick={() => videoHandler(3)}>
              Search
            </div>
          </div>

          <div className="floaty-link-box">
            <div className="floaty-links-icon">
              <GavelIcon
                className={"tabIcon"}
                style={{ color: "white", fontSize: 22 }}
              />
            </div>
            <div className="floaty-links-text" onClick={() => videoHandler(4)}>
              Pre-Bidding and Bulk Pre-Bidding
            </div>
          </div>

          <div className="floaty-link-box">
            <div className="floaty-links-icon">
              <SpeakerNotesIcon
                className={"tabIcon"}
                style={{ color: "white", fontSize: 22 }}
              />
            </div>
            <div className="floaty-links-text" onClick={() => videoHandler(5)}>
              Product Detail Page
            </div>
          </div>

          <div className="floaty-link-box">
            <div className="floaty-links-icon">
              <ContactSupportIcon
                className={"tabIcon"}
                style={{ color: "white", fontSize: 22 }}
              />
            </div>
            <div className="floaty-links-text" onClick={() => videoHandler(6)}>
              Inquiry and Support
            </div>
          </div>

          <div className="floaty-link-box">
            <div className="floaty-links-icon">
              <SupervisedUserCircleIcon
                className={"tabIcon"}
                style={{ color: "white", fontSize: 22 }}
              />
            </div>
            <div className="floaty-links-text" onClick={() => videoHandler(7)}>
              My Page
            </div>
          </div>

          <div className="floaty-link-box">
            <div className="floaty-links-icon">
              <UpdateIcon
                className={"tabIcon"}
                style={{ color: "white", fontSize: 22 }}
              />
            </div>
            <div className="floaty-links-text" onClick={() => videoHandler(8)}>
              Live Auction
            </div>
          </div>

          <div className="floaty-link-box">
            <div className="floaty-links-icon">
              <GavelIcon
                className={"tabIcon"}
                style={{ color: "white", fontSize: 22 }}
              />
            </div>
            <div className="floaty-links-text" onClick={() => videoHandler(9)}>
              Popcorn Bidding
            </div>
          </div>

          <div className="floaty-link-box">
            <div className="floaty-links-icon">
              <AllInclusiveIcon
                className={"tabIcon"}
                style={{ color: "white", fontSize: 22 }}
              />
            </div>
            <div className="floaty-links-text" onClick={() => videoHandler(10)}>
              365 Days Auction
            </div>
          </div>

          <div className="floaty-link-box">
            <div className="floaty-links-icon">
              <InsertDriveFileIcon
                className={"tabIcon"}
                style={{ color: "white", fontSize: 22 }}
              />
            </div>
            <div className="floaty-links-text" onClick={() => videoHandler(11)}>
              Invoice
            </div>
          </div>

          <div className="floaty-link-box">
            <div className="floaty-links-icon">
              <PersonIcon
                className={"tabIcon"}
                style={{ color: "white", fontSize: 22 }}
              />
            </div>
            <div className="floaty-links-text" onClick={() => videoHandler(12)}>
              Profile
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && renderIframeVideo()}
    </RightTabsWrapper>
  );
}
