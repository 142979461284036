import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import { Container, Drawer, useMediaQuery } from "@material-ui/core";
import Colors from "../../../common/materials/colors";
import HeaderMenu from "./HeaderMenu";
import {
  StyledLogoBackground,
  StyledLeftAlignedContainer,
  StyledRightAlignedContainer,
  HiddenButton,
} from "../../../pages/LandingPage/Header/StyledComponent";
import Logo from "../../../pages/LandingPage/Static/Icons/Logo";
import LanguageSelector from "../../../pages/LandingPage/Header/LanguageSelector";
import NotificationToggle from "../../../common/elements/notification/NotificationToggle";
import styled from "styled-components";
import { CommonHorizontalPadding } from "../../../pages/LandingPage/StyledComponents";
import ProfileMenu from "./ProfileMenu";
import { Box, IconButton, useTheme } from "@mui/material";
import CloseIcon from "@material-ui/icons/Close";
import { MenuData, SubDoaminMenuData } from "./MenuData";
import MobileMenus from "./MobileMenus";

const HeaderContainer = styled(CommonHorizontalPadding)`
  position: sticky;
  top: 0;
  z-index: 1001;
  background-color: ${Colors.white};
  display: flex;
  align-items: center;
  -webkit-box-shadow: 0 3px 5px #cccccc90;
  -moz-box-shadow: 0 3px 5px #cccccc90;
  box-shadow: 0 3px 5px #cccccc90;
`;
const StyledLogo = styled.img`
  width: 45%;
  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    width: 40px;
    display: flex;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    width: 45%;
  }
`;

const WebHeader = ({ isZipangDomain, isGlowstar, isSubdomaincontain }: any) => {
  return (
    <HeaderContainer style={{ position: "fixed" }}>
      <Container>
        <Grid container alignItems="center">
          <Grid xs={1} item>
            <StyledLogoBackground>
              {isZipangDomain ? (
                <StyledLogo
                  src="/images/zipangLogo.png"
                  alt="zipang image #1"
                />
              ) : (
                <>
                  {isGlowstar ? (
                    <StyledLogo
                      src="https://ds29nc3ljdjce.cloudfront.net/icon.png"
                      alt="glowstart url"
                    />
                  ) : (
                    <Logo />
                  )}
                </>
              )}
            </StyledLogoBackground>
          </Grid>
          <StyledLeftAlignedContainer xs={8} item>
            {isSubdomaincontain ? (
              <HeaderMenu menuData={MenuData} />
            ) : (
              <HeaderMenu menuData={SubDoaminMenuData} />
            )}
          </StyledLeftAlignedContainer>
          <StyledRightAlignedContainer
            xs={1}
            item
          ></StyledRightAlignedContainer>
          <StyledRightAlignedContainer xs={2} item>
            <div
              style={{
                display: "flex",
                gap: "15px",
                alignItems: "center",
              }}
            >
              <LanguageSelector />
              <NotificationToggle />
              <ProfileMenu />
            </div>
          </StyledRightAlignedContainer>
          <HiddenButton id="hiddenButton"></HiddenButton>
        </Grid>
      </Container>
    </HeaderContainer>
  );
};

export const MobileHeader = ({
  isZipangDomain,
  isGlowstar,
  isSubdomaincontain,
}: any) => {
  const [open, setState] = useState(false);
  //function that is being called every time the drawer should open or close, the keys tab and shift are excluded so the user can focus between the elements with the keys
  const toggleDrawer = (open: any) => (event: any) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    //changes the function state according to the value of open
    setState(open);
  };

  return (
    <HeaderContainer
      className="headerContainer"
      style={{ position: "fixed", height: "50px" }}
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{ position: "absolute", width: "100%" }}
      >
        <StyledLogoBackground onClick={toggleDrawer(true)}>
          {isZipangDomain ? (
            <StyledLogo src="/images/zipangLogo.png" alt="zipangLogo#1" />
          ) : (
            <>
              {isGlowstar ? (
                <StyledLogo
                  src="https://ds29nc3ljdjce.cloudfront.net/icon.png"
                  alt="glowstart url"
                />
              ) : (
                <Logo />
              )}
            </>
          )}
        </StyledLogoBackground>
        <div
          style={{
            display: "flex",
            gap: "15px",
            alignItems: "center",
            marginRight: "20px",
          }}
        >
          <LanguageSelector />
          <NotificationToggle />
          <ProfileMenu />
        </div>
      </Grid>
      <Drawer anchor="top" open={open} onClose={toggleDrawer(false)}>
        <Box
          sx={{
            p: 2,
            height: 1,
            backgroundColor: "white",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <IconButton sx={{ mb: 1 }}>
              <CloseIcon onClick={toggleDrawer(false)} />
            </IconButton>
            <div
              style={{
                display: "flex",
                gap: "15px",
                alignItems: "center",
              }}
            ></div>
          </div>
          {isSubdomaincontain ? (
            <Box sx={{ mb: 1, alignItems: "center" }}>
              {MenuData.map((item: any, index: any) => {
                return <MobileMenus item={item} key={index} />;
              })}
            </Box>
          ) : (
            <Box sx={{ mb: 1, alignItems: "center" }}>
              {SubDoaminMenuData.map((item: any, index: any) => {
                return <MobileMenus item={item} key={index} />;
              })}
            </Box>
          )}
        </Box>
      </Drawer>
    </HeaderContainer>
  );
};

// header
const Header = () => {
  let isZipangDomain = localStorage.getItem("isZipangDomain");
  let isGlowstar = localStorage.getItem("isGlowstarDomain");
  let subdomainType = localStorage.getItem("subDomain");

  let domainArray: any = ["localhost", "zipang"];
  let isSubdomaincontain: boolean = domainArray.includes(subdomainType);

  if (isZipangDomain !== null) {
    isZipangDomain = JSON.parse(isZipangDomain);
  }

  if (isGlowstar !== null) {
    isGlowstar = JSON.parse(isGlowstar);
  }
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between("xs", "md"));
  return isMobile ? (
    <MobileHeader
      isZipangDomain={isZipangDomain}
      isGlowstar={isGlowstar}
      isSubdomaincontain={isSubdomaincontain}
    />
  ) : (
    <WebHeader
      isZipangDomain={isZipangDomain}
      isGlowstar={isGlowstar}
      isSubdomaincontain={isSubdomaincontain}
    />
  );
};

export default Header;
