import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import styled from "styled-components";
import ImageCard from "./ImageCard";
import { useTranslation } from "react-i18next";

const StyledBoxContainer = styled(Grid)`
  padding: 4em;
  color: black;
  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    padding: 1em;
  }
`;
const StyledImageBox = styled(Grid)`
  padding: 4em;
  color: black;
  margin: auto !important;
  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    padding: 0.6em;
    display: flex !important;
    overflow-y: auto !important;
    flex-wrap: nowrap !important;
    width: 100vw !important;
    max-width: none !important;
    margin: 0 !important;
  }
`;

function Seller() {
  let { t } = useTranslation();
  return (
    <>
      {" "}
      <Grid
        container
        style={{
          marginTop: "3em",
          marginBottom: "2em",
          fontFamily: "Playfair Display",
        }}
      >
        <div
          style={{
            color: "black",
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            display: "flex",
            marginBottom: "1em",
          }}
        >
          <Typography variant="h4" fontWeight={500}>
            {t("common:zipangLanding:heading:seller")}
          </Typography>
        </div>

        <StyledBoxContainer
          container
          xs={12}
          sx={{
            py: 0,
          }}
        >
          <Grid item xs={12} sm={6} md={4}>
            <Box
              style={{
                padding: "30px",
                textAlign: "center",
              }}
            >
              <img
                src={
                  "https://ds29nc3ljdjce.cloudfront.net/zipang/globeicon.png"
                }
                alt={`Item`}
                style={{ maxWidth: "10%" }}
              />

              <Typography
                variant="subtitle1"
                style={{ marginTop: "10px", fontWeight: 700 }}
              >
                {t("common:zipangLanding:sellerTrans:firstBox:heading")}
              </Typography>

              <Typography variant="body1" style={{ marginTop: "10px" }}>
                {t("common:zipangLanding:sellerTrans:firstBox:description")}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box
              style={{
                padding: "30px",
                textAlign: "center",
                fontFamily: "Playfair Display",
              }}
            >
              <img
                src={
                  "https://ds29nc3ljdjce.cloudfront.net/zipang/credibility.png"
                }
                alt={`Item`}
                style={{ maxWidth: "15%" }}
              />
              <Typography
                variant="subtitle1"
                style={{ marginTop: "10px", fontWeight: 700 }}
              >
                {t("common:zipangLanding:sellerTrans:secondBox:heading")}
              </Typography>
              <Typography variant="body1" style={{ marginTop: "10px" }}>
                {t("common:zipangLanding:sellerTrans:secondBox:description")}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box
              style={{
                padding: "30px",
                textAlign: "center",
              }}
            >
              <img
                src={
                  "https://ds29nc3ljdjce.cloudfront.net/zipang/transparency.png"
                }
                alt={`Item`}
                style={{ maxWidth: "10%" }}
              />
              <Typography
                variant="subtitle1"
                style={{ marginTop: "1px", fontWeight: 700 }}
              >
                {t("common:zipangLanding:sellerTrans:thirdBox:heading")}
              </Typography>
              <Typography variant="body1" style={{ marginTop: "10px" }}>
                {t("common:zipangLanding:sellerTrans:thirdBox:description")}
              </Typography>
            </Box>
          </Grid>
        </StyledBoxContainer>

        <StyledImageBox container xs={12} spacing={6}>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            sx={{
              minWidth: "350px",
            }}
          >
            <ImageCard
              imageUrl="https://ds29nc3ljdjce.cloudfront.net/zipang/selldiamondsfirst.png"
              text={
                <>
                  <Box>
                    <Typography
                      variant="subtitle1"
                      style={{ textAlign: "center", padding: "10px" }}
                    >
                      {t(
                        "common:zipangLanding:sellerTrans:GiveDetails:heading"
                      )}
                    </Typography>
                    <p
                      style={{
                        marginTop: "20px",
                        textAlign: "center",
                        display: "flex",
                        gap: "5px",
                        justifyContent: "center",
                      }}
                    >
                      <a
                        target="_blank"
                        href={"https://wa.me/message/DY37FMYCUSEJN1"}
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          src={
                            "https://ds29nc3ljdjce.cloudfront.net/zipang/whatsapp_black.png"
                          }
                          alt={`Item`}
                          style={{ maxHeight: "3vh" }}
                        />
                      </a>
                      <a
                        target="_blank"
                        href={"https://lin.ee/2wg2q8j"}
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          src={
                            "https://ds29nc3ljdjce.cloudfront.net/zipang/line_black.png"
                          }
                          alt={`Item`}
                          style={{ maxHeight: "3vh" }}
                        />
                      </a>

                      <img
                        src={
                          "https://ds29nc3ljdjce.cloudfront.net/zipang/mail_black.png"
                        }
                        alt={`Item`}
                        style={{ maxHeight: "3vh" }}
                      />
                      <a
                        target="_blank"
                        href={
                          "https://ds29nc3ljdjce.cloudfront.net/zipang/weChat_QR.jpeg"
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          src={
                            "https://ds29nc3ljdjce.cloudfront.net/zipang/wechat_black.png"
                          }
                          alt={`Item`}
                          style={{ maxHeight: "3vh" }}
                        />
                      </a>
                    </p>
                  </Box>
                </>
              }
              heading={`${t(
                "common:zipangLanding:sellerTrans:GiveDetails:title"
              )}`}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            sx={{
              minWidth: "350px",
            }}
          >
            <ImageCard
              imageUrl="https://ds29nc3ljdjce.cloudfront.net/zipang/sendGoods.JPEG"
              text={
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "10px",
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      style={{ marginTop: "10px", textAlign: "center" }}
                    >
                      {t("common:zipangLanding:sellerTrans:sendGoods:heading")}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      style={{ marginTop: "10px", textAlign: "center" }}
                    >
                      <div>
                        {t(
                          "common:zipangLanding:sellerTrans:sendGoods:subHeading"
                        )}
                      </div>
                      <div>
                        {t(
                          "common:zipangLanding:sellerTrans:sendGoods:thirdSubHeading"
                        )}
                      </div>
                    </Typography>
                  </div>
                </>
              }
              heading={`${t(
                "common:zipangLanding:sellerTrans:sendGoods:title"
              )}`}
              textTop={false}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            sx={{
              minWidth: "350px",
            }}
          >
            <ImageCard
              imageUrl="https://ds29nc3ljdjce.cloudfront.net/zipang/selldiamondsSec.png"
              text={
                <>
                  <Typography
                    variant="subtitle1"
                    style={{
                      marginTop: "10px",
                      textAlign: "center",
                      padding: "10px",
                    }}
                  >
                    {t("common:zipangLanding:sellerTrans:sellorEnjoy:heading")}
                  </Typography>
                </>
              }
              heading={`${t(
                "common:zipangLanding:sellerTrans:sellorEnjoy:title"
              )}`}
              textTop={false}
            />
          </Grid>
        </StyledImageBox>
      </Grid>
    </>
  );
}

export default Seller;
