import {
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../common/elements/Button";
import { useDialog } from "../../common/elements/Dialog/Dialog";
import Colors from "../../common/materials/colors";
import Cross from "../LandingPage/Static/Icons/Cross";
import Logo from "../LandingPage/Static/Icons/Logo";
import { StyledLogoContainer } from "../zipang/Header/StyledComponent";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "2em",
  },
  margin: {
    marginTop: theme.spacing(3),
  },
  fullContainer: {
    height: "100vh",
    alignItems: "baseline",
  },
  filterButton: {
    border: `1px solid ${Colors.grey}`,
    padding: "0.5em 2em 0.3em 2em",
    borderRadius: "40px !important",
  },
}));

const priceList = [
  {
    name: "common:h2l",
    value: 0,
  },
  {
    name: "common:l2h",
    value: 1,
  },
];
export const itemsList = [
  {
    name: "common:jwlry",
    value: 0,
  },
  {
    name: "common:diamond",
    value: 1,
  },
];

const Filters = ({
  handleClose,
  onChange,
  isZipang,
}: {
  handleClose: any;
  onChange: (price: any, item: any) => any;
  isZipang: boolean;
}) => {
  const { t } = useTranslation();
  const [price, setPriceFilter] = useState("");
  const [items, setItemFilter] = useState("");
  const classes = useStyles();
  const history = useHistory();

  const handlePriceChange = (e: { target: { value: any } }) => {
    const val = e.target.value;
    setPriceFilter(val);
  };

  const handleItemsChange = (e: { target: { value: any } }) => {
    const val = e.target.value;
    setItemFilter(val);
  };

  const handleReset = () => {
    setPriceFilter("");
    setItemFilter("");
    onChange("", "");
  };

  const handleRefine = () => {
    onChange(price, items);
  };
  return (
    <Grid container className={classes.fullContainer}>
      <Grid container classes={classes} justifyContent="space-between">
        {isZipang ? (
          <span
            onClick={() => {
              if (window.location.pathname.includes("zipangAuctionslist")) {
                history.push("/zipang");
              }
            }}
          >
            <img
              src="https://ds29nc3ljdjce.cloudfront.net/zipang/zipangLogo.png"
              alt="zipangLogo#1"
              style={{ height: "58px", width: "60px", cursor: "pointer" }}
              onClick={() => {
                window.location.hash = "TOP";
              }}
            />
          </span>
        ) : (
          <Logo />
        )}
        <Button type="" onClick={handleClose}>
          <Cross />
        </Button>
      </Grid>
      <Grid container classes={classes}>
        <Typography className="fontBold">{t("common:filSearch")}</Typography>
        <Grid item xs={12}>
          <FormControl variant="outlined" fullWidth className={classes.margin}>
            <InputLabel id={"price-label"}>
              {t("common:preview:price")}
            </InputLabel>
            <Select
              id={"price"}
              name={"price"}
              value={price}
              onChange={handlePriceChange}
            >
              {priceList.map((filter: any) => (
                <MenuItem key={filter.name} value={t(filter?.name)}>
                  {t(filter?.name)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl variant="outlined" fullWidth className={classes.margin}>
            <InputLabel id={"item-label"}>{t("common:items")}</InputLabel>
            <Select
              id={"item"}
              name={"item"}
              value={items}
              onChange={handleItemsChange}
            >
              {itemsList.map((filter: any) => (
                <MenuItem key={filter.name} value={t(filter?.name)}>
                  {t(filter?.name)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid
        container
        classes={classes}
        justifyContent="center"
        alignItems="stretch"
        direction="column"
      >
        <Button type="contained-black" onClick={handleReset}>
          {t("common:reset")}
        </Button>
        <br />
        <Button
          type={isZipang ? "contained-dark-Red" : "contained-dark"}
          onClick={handleRefine}
        >
          {t("common:refine")}
        </Button>
      </Grid>
    </Grid>
  );
};

const MobileFilters = ({
  onChange,
  isZipang,
}: {
  onChange: (price: any, item: any) => any;
  isZipang: boolean;
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [createDialog, closeDialog] = useDialog();

  const handleOnChange = (price: any, item: any) => {
    onChange(price, item);
    closeDialog();
  };

  const handleOnClick = () => {
    createDialog({
      children: (
        <Filters
          handleClose={closeDialog}
          onChange={handleOnChange}
          isZipang={isZipang}
        />
      ),
      fullScreen: true,
      open: true,
    });
  };
  return (
    <Grid>
      <Button
        className={classes.filterButton}
        onClick={handleOnClick}
        type={undefined}
      >
        {t("common:button:filter")}
      </Button>
    </Grid>
  );
};

export default MobileFilters;
