import React, { useRef, useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "../../common/elements/Button";
import { LandingPagePlayFairFont } from "./StyledComponents";

import { DialogProvider } from "../../common/elements/Dialog/Dialog";
import { useMediaQuery } from "@material-ui/core";
import MobileMenu from "./Header/MobileMenu";
import { useTranslation } from "react-i18next";
import ZipangHeader, { MobileSignIn } from "./Header/ZipangHeader";
import ZipangHero from "./Hero/ZipangHero";
import { Box, Typography } from "@mui/material";
import ZipangUpcomingAuc from "./Hero/ZipangUpcomingAuc";
import styled from "styled-components";
import Footer from "./Footer";
import Seller from "./Section/Seller";
import Buyer from "./Section/Buyer";
import { StyledSellerButtonsContainer } from "./Hero/StyledCommon";
import { IProductCardProps } from "../LandingPage/ProductCard/ProductCard";
import ProductList from "../LandingPage/ProductCard/ProductList";
import { getProducts } from "../LandingPage/Services";
import Section from "./Section/Section";
import ZipangSection from "./Section/ZipangSection";

const LandingPageOpenSansFont = styled.div`
  font-family: Playfair Display;
  overflow-x: hidden;
`;

const ZipangLandingPage = () => {
  const howItWorksRef = useRef();
  localStorage.setItem("paymentConditionAgree", "false");
  localStorage.setItem("refreshFlag", "false");
  window.document.title = "Zipang Auctions";
  const isMobile = useMediaQuery("(max-width: 820px)");
  const { t } = useTranslation();
  const [upatedIndex, setUpdatedIndex] = useState(0);
  const [showSignIn, setShowSignIn] = useState(false);
  const [liveProductsList, setLiveProductList] = useState(
    [] as IProductCardProps[]
  );

  useEffect(() => {
    localStorage.setItem("sellerBtnClick", "false");
    getProductsList();
  }, []);

  const handleLogin = () => setShowSignIn(true);
  const handleClose = () => {
    setShowSignIn(false);
    localStorage.setItem("sellerBtnClick", "false");
  };

  const getProductsList = async () => {
    const list = await getProducts();
    if (!list?.results) {
      return;
    }
    if (list.results.length !== 0) {
      setLiveProductList(list.results.slice(0, 3) as IProductCardProps[]);
    }
  };

  return (
    <DialogProvider>
      <div className="mainContainer">
        {isMobile ? null : <ZipangHeader />}
        <LandingPageOpenSansFont>
          {isMobile ? <ZipangHeader /> : null}
          <div>
            {/* TOP */}
            <Box
              id="TOP"
              sx={{
                padding:
                  upatedIndex === 0
                    ? "20px 0px"
                    : isMobile
                    ? "40px 0px"
                    : "20px 0px",
                minHeight: "100vh",
                fontFamily: "OpenSans-Regular",
              }}
            >
              <LandingPagePlayFairFont>
                <ZipangHero
                  isMobile={isMobile}
                  inViewRef={howItWorksRef}
                  setUpdatedIndex={setUpdatedIndex}
                />
              </LandingPagePlayFairFont>
              {isMobile && <MobileMenu />}

              <StyledSellerButtonsContainer
                container
                upatedIndex={upatedIndex === 0}
                justifyContent="center"
                alignContent="center"
              >
                <Button
                  isMobile={isMobile}
                  wide
                  widePadding
                  type="contained-new-black"
                  onClick={() => {
                    window.location.hash = "SELLERS";
                  }}
                >
                  {t("common:zipangLanding:heading:seller")}
                </Button>
                <Button
                  isMobile={isMobile}
                  wide
                  widePadding
                  type="contained-red"
                  onClick={() => {
                    window.location.hash = "BUYER";
                  }}
                >
                  {t("common:zipangLanding:heading:buyer")}
                </Button>
              </StyledSellerButtonsContainer>
              {liveProductsList.length ? (
                <ZipangSection
                  id="liveAuction"
                  label={t("common:liveFeatureAuc")}
                  pageLink="/zipangAuctionslist/live"
                  isZipang={true}
                >
                  <ProductList
                    disableMobileView
                    isMobile={isMobile}
                    ads={[]}
                    productsList={liveProductsList}
                    isZipang={true}
                    isLandingPage={true}
                  />
                </ZipangSection>
              ) : null}
            </Box>

            {/* UPCOMMing */}
            <Grid
              container
              style={{
                background:
                  "url(https://ds29nc3ljdjce.cloudfront.net/zipang/whiteBackground.png)",
                padding: "60px 0px",
                minHeight: "100vh",
                marginTop: isMobile ? "120px" : "40px",
              }}
              id="UPCOMMING"
            >
              <div
                style={{
                  color: "#d92b3c",
                  textAlign: "center",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  display: "flex",
                  marginBottom: "3em",
                }}
              >
                <Typography
                  variant="h3"
                  fontWeight={600}
                  style={{
                    fontFamily: "Playfair Display",
                    background: "white",
                  }}
                >
                  {t("common:zipangLanding:tab:upcomingAuc")}
                </Typography>
              </div>

              <ZipangUpcomingAuc
                isMobile={isMobile}
                inViewRef={howItWorksRef}
              />
              <Grid
                container
                justifyContent="center"
                alignContent="center"
                direction={isMobile ? "column" : "row"}
                style={{ marginTop: "2em" }}
              >
                <Button
                  isMobile={isMobile}
                  wide
                  widePadding
                  type="contained-red"
                  onClick={() => {
                    window.open(
                      "https://calendly.com/myauctions/zipangauction?month=2024-08",
                      "_blank"
                    );
                  }}
                >
                  {t("common:zipangLanding:button:reserveSlot")}
                </Button>
              </Grid>
            </Grid>

            <Grid
              id="SELLERS"
              style={{
                padding: "60px 0px",
                minHeight: "100vh",
              }}
            >
              <Seller />
              <Grid
                container
                justifyContent="center"
                alignContent="center"
                direction={isMobile ? "column" : "row"}
                style={{ marginTop: "2em" }}
              >
                <Button
                  isMobile={isMobile}
                  wide
                  widePadding
                  type="contained-red"
                  onClick={() => {
                    localStorage.setItem("sellerBtnClick", "true");
                    handleLogin();
                  }}
                >
                  Start Selling
                </Button>
              </Grid>
            </Grid>

            <Grid
              id="BUYER"
              style={{
                padding: "60px 0px",
                minHeight: "100vh",
              }}
            >
              <Buyer />
              <Grid
                container
                justifyContent="center"
                alignContent="center"
                direction={isMobile ? "column" : "row"}
                style={{ marginTop: "2em" }}
              >
                <Button
                  isMobile={isMobile}
                  wide
                  widePadding
                  type="contained-red"
                  onClick={handleLogin}
                >
                  Start Bidding
                </Button>
              </Grid>
              <Grid container style={{ marginTop: isMobile ? "2em" : "2em" }}>
                <div
                  style={{
                    color: "black",
                    textAlign: "center",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    display: "flex",
                    marginBottom: "3em",
                    padding: "10px",
                  }}
                >
                  <Typography
                    variant="h5"
                    fontWeight={500}
                    style={{ fontFamily: "Playfair Display" }}
                  >
                    {t("common:zipangLanding:registerTitle")}
                  </Typography>
                </div>
              </Grid>
            </Grid>
            <Footer />
          </div>
        </LandingPageOpenSansFont>
        <MobileSignIn show={showSignIn} onClose={handleClose} />
      </div>
    </DialogProvider>
  );
};

export default ZipangLandingPage;
