import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const StyledBoxContainer = styled(Grid)`
  padding: 4em;
  color: black;
  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    padding: 1em;
  }
`;

function Buyer() {
  let { t } = useTranslation();
  return (
    <>
      {" "}
      <Grid container style={{ marginTop: "3em", marginBottom: "4em" }}>
        <div
          style={{
            color: "black",
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            display: "flex",
            marginBottom: "1em",
          }}
        >
          <Typography
            variant="h4"
            fontWeight={500}
            style={{ fontFamily: "Playfair Display" }}
          >
            {t("common:zipangLanding:heading:buyer")}
          </Typography>
        </div>
        <StyledBoxContainer container xs={12}>
          <Grid item xs={12} sm={6} md={4}>
            <Box
              style={{
                padding: "30px",
                textAlign: "center",
                fontFamily: "Playfair Display",
              }}
            >
              <img
                src={"https://ds29nc3ljdjce.cloudfront.net/zipang/goods.png"}
                alt={`Item`}
                style={{ maxWidth: "10%" }}
              />

              <Typography
                variant="subtitle1"
                style={{ marginTop: "10px", fontWeight: 700 }}
              >
                {t("common:zipangLanding:buyerTrans:firstBox:heading")}
              </Typography>

              <Typography variant="body1" style={{ marginTop: "10px" }}>
                {t("common:zipangLanding:buyerTrans:firstBox:description")}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box
              style={{
                padding: "30px",
                textAlign: "center",
                fontFamily: "Playfair Display",
              }}
            >
              <img
                src={"https://ds29nc3ljdjce.cloudfront.net/zipang/tech.png"}
                alt={`Item`}
                style={{ maxWidth: "10%" }}
              />
              <Typography
                variant="subtitle1"
                style={{ marginTop: "10px", fontWeight: 700 }}
              >
                {t("common:zipangLanding:buyerTrans:secondBox:heading")}
              </Typography>
              <Typography variant="body1" style={{ marginTop: "10px" }}>
                {t("common:zipangLanding:buyerTrans:secondBox:description")}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box
              style={{
                padding: "30px",
                textAlign: "center",
                fontFamily: "Playfair Display",
              }}
            >
              <img
                src={
                  "https://ds29nc3ljdjce.cloudfront.net/zipang/credibilty_transparency.png"
                }
                alt={`Item`}
                style={{ maxWidth: "10%" }}
              />
              <Typography
                variant="subtitle1"
                style={{ marginTop: "10px", fontWeight: 700 }}
              >
                {t("common:zipangLanding:buyerTrans:thirdBox:heading")}
              </Typography>
              <Typography variant="body1" style={{ marginTop: "10px" }}>
                {t("common:zipangLanding:buyerTrans:thirdBox:description")}
              </Typography>
            </Box>
          </Grid>
        </StyledBoxContainer>
      </Grid>
    </>
  );
}

export default Buyer;
