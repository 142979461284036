import { Grid, makeStyles, useMediaQuery } from "@material-ui/core";
import React from "react";
import {
  StyledImageCommonComponent,
  StyledImageCommonContainer,
} from "../StyledComponents";
import CloseIcon from "@material-ui/icons/Close";
import { Box } from "@mui/material";
const useStyles = makeStyles({
  cross: {
    position: "absolute",
    right: "1em",
    top: "1em",
    margin: 0,
  },
  container: {
    position: "relative",
  },
  flexWrap: {
    flexWrap: "nowrap",
  },
});

const ZipangAdvModal = ({ close }: { close: () => void }) => {
  const isMobile = useMediaQuery("(max-width: 760px)");
  const classes = useStyles();
  let languageCode = localStorage.getItem("language");
  return (
    <Grid
      container
      justifyContent="center"
      classes={classes}
      className={isMobile ? "" : classes.flexWrap}
    >
      <Grid>
        <StyledImageCommonContainer
          style={
            isMobile
              ? {
                  height: "420px",
                  width: "340px",
                  padding: "5px",
                  background: "white",
                }
              : {
                  height: "610px",
                  width: "610px",
                  padding: "5px",
                  background: "white",
                }
          }
        >
          <Box
            sx={{
              position: "absolute",
              right: -10,
              borderRadius: "10px",
              top: -10,
              bgcolor: "white",
            }}
            onClick={close}
          >
            <CloseIcon style={{ cursor: "pointer" }} />
          </Box>
          <Box
            sx={
              !isMobile
                ? {
                    height: "50px",
                    right: "50px",
                    top: "346px",
                    width: "150px",
                    position: "absolute",
                    cursor: "pointer",
                  }
                : {
                    position: "absolute",
                    top: "241px",
                    height: "29px",
                    width: "75px",
                    right: "30px",
                    cursor: "pointer",
                  }
            }
            onClick={() => {
              window.open(
                "https://calendly.com/myauctions/zipangauction",
                "_blank"
              );
            }}
          ></Box>
          <StyledImageCommonComponent
            src={
              languageCode === "zh_CN"
                ? "https://ds29nc3ljdjce.cloudfront.net/auction_calendar_ch_june_2023.png"
                : "https://ds29nc3ljdjce.cloudfront.net/auction_calendar_en_june_2023.png"
            }
            style={{ height: "100%" }}
          />
        </StyledImageCommonContainer>
      </Grid>
    </Grid>
  );
};

export default ZipangAdvModal;
