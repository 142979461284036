import React from "react";
import { match } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";

import AppLayout from "../../components/layouts/AppLayout";
import IRouterComponentProps from "../../models/IRouterComponentProps";
import DetailsCarousel, {
  StyledGrid,
  StyledNotAvailableImage,
  StyledProductImage,
} from "./details/DetailsCarousel";
import PreBiddingForm from "../../components/products/details/PreBiddingForm";
import DetailsNotes from "../../components/products/details/DetailsNotes";
import AuthService from "../auth/AuthService";
import { getProductDetailUrl, url } from "../../common/config/app.endpoints";
import PerItemDescription from "./details/PerItemDescription";
import Alert from "../../common/elements/Alert";
import LoadingSpinner from "../../common/elements/LoadingSpinner";
import ApiTimeout from "../../modules/ApiTimeout";
import AddorRemoveWatchlist from "./preview/AddorRemoveWatchlist";
import { oAuth } from "../../routes/basic/BasicAppRouter";
import BlockUserModal from "./BlockUser/BlockUserModal";
import { StyledModal } from "../../common/materials/GenericStyleComponent";
import {
  backgroundGradient,
  buttonGradient,
} from "../../common/materials/LinearGradient";
import ShareProductLinkView from "../../components/products/ShareProductLinkView";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { sideMenuGradient } from "../../common/materials/LinearGradient";
import EllipsisText from "../../common/elements/EllipsisText";
import ProductDescription from "../shared/ProductDescription";

const StyledLayoutGrid = styled(Grid)`
  flexgrow: 1;
  width: 100% !important;
  display: flex-wrap;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  background: ${(props) => props.theme.colors.base.white};
`;

const StyledActionContainer = styled(Grid)``;

const StyledTitleContainer = styled(Grid)`
  padding: 20px 0;
  ${(props) => props.theme.breakpoints.down("xs")} {
    padding: 15px 10px 10px;
  }
`;
const StyledBackBtnContainer = styled(Grid)`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  font-weight: bold;
  .spanText {
    font-size: 1em;
    margin-left: 10px;
    cursor: pointer;
  }
`;

const StyledBackBtn = styled.img`
  width: 1.5em;
  height: 1.5em;
  cursor: pointer;
`;
const StyledBrandName = styled.div`
  color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  font-size: 1.5em;
  font-weight: bold;
`;

const StyledProductCarousalColumn = styled(Grid)`
  padding-right: 20px;
  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: 0px;
  }
`;

const StyledItemDesc = styled(Grid)`
  border: 1px solid
    ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  font-weight: bold;
  color: ${(props) => props.theme.colors.base.grey};
  padding: 8px;
  border-radius: 3px;
  font-size: 0.8em;
  margin-top: 10px;
  flex-direction: row;
  display: flex;
`;

const StyledGridWrapper = styled(Grid)`
  display: flex;
  border-bottom: 1px solid
    ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  padding: 10px;
`;

const StyledContentWrapper = styled(Grid)`
  padding-top: 20px;
`;

const StyledArrowIcons = styled.img`
  width: 2em;
  height: 2em;
  cursor: pointer;
  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    margin-right: 30px;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    margin-right: 20px;
  }
`;

const StyledName = styled(Grid)`
  font-size: 1em;
  font-weight: bold;
  color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  text-align: right;
`;

const StyledWrapper = styled(Grid)`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 0.5em;
  text-align: center;
`;

const StyledArrowWrapper = styled(Grid)`
  display: flex;
  justify-content: center;
`;

interface IButtonProps {
  isSubmit?: boolean;
}

const StyledButton = styled.button<IButtonProps>`
  color: ${(props) => props.theme.colors.base.white};
  border: none;
  font-size: 1em;
  padding: 0.6em;
  border-radius: 5px;
  background: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  background: ${buttonGradient};
  border: 1px solid ${(props) => props.theme.colors.base.white};
  &:hover {
    border: 1px solid ${(props) => props.theme.colors.base.textColor};
    cursor: pointer;
  }
`;
const StyledButtonContainer = styled.div`
  text-align: center;
`;

const StyledHeaderContainer = styled(Grid)`
  padding: 0 !important;
  background: ${(props) => props.theme.colors.base.darkBlue};
  background: ${sideMenuGradient};
  height: 66px;
`;

const StyledWrapperTop = styled(Grid)`
  display: flex;
  //justify-content: space-between;
`;

const StyledResetPaddingContainer = styled(Grid)`
  background: ${(props) => props.theme.colors.base.transparent};
  justify-content: center;
  align-items: center;
`;

const StyledLogoWrapper = styled.div`
  display: inline-block;
  width: 100%;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    img {
      width: 20%;
    }
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    img {
      width: 20%;
    }
  }
  ${(props) => props.theme.breakpoints.between("lg", "xl")} {
    img {
      width: 20%;
    }
  }
  display: flex;
  margin-left: 15px;
  margin-top: 15px;
`;
export const StyledZipangLogoWrapper = styled.div`
  display: inline-block;
  width: 100%;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    img {
      width: 40%;
    }
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    img {
      width: 40%;
    }
  }
  ${(props) => props.theme.breakpoints.between("lg", "xl")} {
    img {
      width: 65%;
    }
  }
  display: flex;
  margin-left: 15px;
  margin-top: 15px;
`;

const StyledLogoImage = styled.img`
  height: 2.5em;
  width: 1.5em;
`;
export const StyledZipangLogoImage = styled.img`
  height: 2.5em;
  width: 4.5em;
`;
const StyledTextContainer = styled.div`
  background: ${(props) => props.theme.colors.base.transparent};
  padding: 5px 5px;
  color: ${(props) => props.theme.colors.base.white};
  font-size: 1.5em;
  font-family: SF-Display-Medium;
`;

const StyledButtonWrapper = styled.div`
  float: right;
  padding: 8px;
  display: flex;
`;

const StyledSorryTextWrapper = styled(Grid)`
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 30px;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    font-size: 70px;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    font-size: 100px;
  }
  font-family: Calibre-Regular;
  color: ${(props) => props.theme.colors.base.darkGrey};
`;

const StyledTextWrapper = styled(Grid)`
  font-size: 30px;
  font-family: Calibre-Regular;
  color: ${(props) => props.theme.colors.base.grey};
`;

const StyledOOPsContainer = styled.div`
  text-align: center;
  width: 100%;
`;

const StyledWrapperID = styled(Grid)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledProductName = styled.div`
  color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  font-size: 1em;
`;

const StyledArrowIconsID = styled.img`
  width: 1.5em;
  height: 1.5em;
  cursor: pointer;
  margin-left: 15px;
  margin-top: 10px;
  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    margin-right: 10px;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    margin-right: 5px;
  }
`;

interface DetailParams {
  auctionId: string;
  productId: string;
  auctionDate: string;
}
let auctionDate: string = "";

interface IState {
  items: any;
  openToast: boolean;
  showAlertMessage: string;
  index: number;
  isLoading: boolean;
  alertType: string;
  auctionProducts: Array<any>;
  totalElements: string | number;
  apiTimeout: boolean;
  userBlock: boolean;
  openShareModal: boolean;
  isPDView: boolean;
  isProductAvailable: boolean;
  fullData: any;
  count: number;
}

interface IProductDetailsViewProps extends IRouterComponentProps {
  match: match<DetailParams>;
  t?: any;
  location: any;
}

let productList: [] = [];
let filteredValues: any = "";
let joinedAuctions: any = [];
let body: any = "";
let data = {};
var filter: [] | null | string = [];
var productID: any = "";

export default class ShareProductDetailsView extends React.Component<
  IProductDetailsViewProps,
  IState
> {
  constructor(props: IProductDetailsViewProps) {
    super(props);
    this.state = {
      items: "",
      openToast: false,
      showAlertMessage: "",
      index: 0,
      isLoading: false,
      alertType: "",
      auctionProducts: [],
      totalElements: "",
      apiTimeout: false,
      userBlock: false,
      openShareModal: false,
      isPDView: false,
      isProductAvailable: true,
      count: 0,
      fullData: "",
    };
  }

  Auth = new AuthService();

  componentDidMount() {
    this.setState({
      isLoading: false,
    });
    data = {};
    body = "";
    filter = [];
    if (this.props.match.params.auctionDate !== undefined) {
      auctionDate = this.props.match.params.auctionDate;
    }
    this.getDetails(this.props.match.params.productId);
  }

  componentWillUnmount() {
    this.setState({
      items: "",
      openToast: false,
      showAlertMessage: "",
      index: 0,
      isLoading: false,
      alertType: "",
      auctionProducts: [],
      totalElements: "",
      apiTimeout: false,
    });
    localStorage.removeItem("pathname");
  }

  getDetails = (productId: string | number) => {
    this.setState({
      openToast: false,
    });
    this.setState({
      isLoading: true,
    });
    this.Auth.getRequest(
      `${getProductDetailUrl}/${productId}/detail`,
      (status: number, response: any) => {
        this.setState({
          items: "",
        });
        if (status === 200) {
          if (response.subItems) {
            var rows = [];

            for (var i = 0; i < response.subItems.length; i++) {
              let obj = response.subItems[i];
              if (obj.medias !== undefined) {
                for (var j = 0; j < obj.medias.length; j++) {
                  rows.push(obj.medias[j]);
                }
              }
            }

            var objModify = response;
            objModify.medias = objModify.medias.concat(rows);

            this.setState({
              items: objModify,
              isLoading: false,
              fullData: response,
              isProductAvailable: response.state == "Available" ? true : false,
            });
          } else {
            this.setState({
              items: response,
              isLoading: false,
              fullData: response,
              isProductAvailable: response.state == "Available" ? true : false,
            });
          }
        } else if (status === 403) {
          this.setState({ userBlock: true });
        } else if (status === 505) {
          this.setState({
            apiTimeout: true,
            isLoading: false,
          });
        } else {
          this.setState({
            alertType: "error",
            openToast: true,
            showAlertMessage: response,
            isLoading: false,
          });
        }
      }
    );
  };

  timeout = (timeout: boolean) => {
    if (timeout === true) {
      this.setState({
        apiTimeout: true,
      });
    }
  };

  alertType = (alertType: string, openToast: boolean, showMessage: string) => {
    this.setState({
      alertType: alertType,
      openToast: openToast,
      showAlertMessage: showMessage,
    });
  };

  blockCloseModal = () => {
    this.Auth.logout();
    this.props.history.replace("/");
  };

  shareButtonAction() {
    this.setState({
      openShareModal: true,
    });
  }

  onCloseModal = () => {
    this.setState({
      openShareModal: false,
    });
  };

  onCopyLink = () => {
    this.setState({
      openShareModal: false,
    });
  };

  nextButtonSubItem = () => {
    if (this.state.fullData.subItems.length > this.state.count) {
      this.setState({
        items: this.state.fullData.subItems[this.state.count],
        count: this.state.count + 1,
      });
    }
  };

  previousButtonSubItem = () => {
    if (this.state.fullData.subItems.length === this.state.count) {
      this.setState({
        items: this.state.fullData.subItems[this.state.count - 2],
        count: this.state.count - 1,
      });
    } else if (this.state.count === 1) {
      this.setState({
        items: this.state.fullData,
        count: this.state.count - 1,
      });
    } else {
      if (this.state.count > 0) {
        this.setState({
          items: this.state.fullData.subItems[this.state.count - 2],
          count: this.state.count - 1,
        });
      }
    }
  };

  getProductDescForParcelTypeDiamond = () => {
    const { t } = this.props;
    return `${this.state.fullData.code} ${this.state.fullData.carat} ${t(
      "common:preview:ct"
    )} ${this.state.fullData.color}${" "} ${
      this.state.fullData.clarity
    }${" "} ${this.state.fullData.cutGrade}${" "} ${
      this.state.fullData.flour
    } ${t("common:preview:pol")}=${this.state.fullData.polish} ${t(
      "common:preview:sym"
    )}=${this.state.fullData.symmetry}`;
  };
  getProductDesc = () => {
    const sieveType =
      this.state.fullData.sieve !== undefined &&
      this.state.fullData.sieve === "-"
        ? ""
        : this.state.fullData.sieve;
    const productShape =
      this.state.fullData.shape !== undefined ? this.state.fullData.shape : "";
    return `${this.state.fullData.code}${" "}${
      this.state.fullData.productDescription
    }${" "}${
      this.state.fullData.color
    }${" "}${productShape}${" "}${sieveType}${" "}${
      this.state.fullData.carat
    } ${"Ct"}`;
  };

  onPressSign = (auctionId: string, perId: string, auctionDate: string) => {
    let auctioonuserid = "";
    var joinedAuctions: any = localStorage.getItem("joinedAnnualAuctions");
    joinedAuctions = JSON.parse(joinedAuctions);
    if (joinedAuctions !== null) {
      joinedAuctions.filter((items: any) => {
        if (items.category === "Diamonds") {
          auctioonuserid = items.auctionUserId;
        }
      });
    }
    let languageCode =
      localStorage.getItem("language") === "EN"
        ? "en"
        : localStorage.getItem("language") === "zh_CN"
        ? "zh_CN"
        : "jp";
    if (this.state.fullData.auctionType === "Popcorn_Bidding") {
      window.open(
        `/${languageCode}/preview/tender/details/${auctionId}/${perId}/${auctionDate}`,
        "_blank"
      );
    } else if (this.state.fullData.auctionType === "Annual") {
      window.open(
        `/${languageCode}/365products/details/${auctionId}/${auctioonuserid}/${perId}/${auctionDate}/${false}`,
        "_blank"
      );
    } else {
      window.open(
        `/${languageCode}/preview/details/${auctionId}/${perId}/${auctionDate}`,
        "_blank"
      );
    }
  };
  render() {
    const { t } = this.props;
    return (
      <Grid>
        {this.state.apiTimeout === true ? (
          <ApiTimeout t={t} {...this.props} />
        ) : (
          <Grid>
            {this.state.isLoading === true ? (
              <div className="loader">
                <LoadingSpinner />
              </div>
            ) : (
              [
                this.state.isProductAvailable ? (
                  <StyledLayoutGrid container={true} spacing={0}>
                    <StyledHeaderContainer
                      item={true}
                      xs={12}
                      className="headerContainer"
                    >
                      <StyledWrapperTop container={true} spacing={0}>
                        <StyledResetPaddingContainer item xs={12} sm={6} lg={3}>
                          {localStorage.getItem("subDomain") === "zipang" ? (
                            <StyledZipangLogoWrapper>
                              <StyledZipangLogoImage src="https://ds29nc3ljdjce.cloudfront.net/zipang_logo_normal.png" />
                            </StyledZipangLogoWrapper>
                          ) : (
                            <>
                              {localStorage.getItem("subDomain") ===
                              "isGlowstarDomain" ? (
                                <StyledZipangLogoWrapper>
                                  <StyledZipangLogoImage src="https://ds29nc3ljdjce.cloudfront.net/icon.png" />
                                </StyledZipangLogoWrapper>
                              ) : (
                                <StyledLogoWrapper>
                                  <StyledLogoImage src="/images/logo.png" />
                                  <StyledTextContainer>
                                    {t("common:header:logoText")}
                                  </StyledTextContainer>
                                </StyledLogoWrapper>
                              )}
                            </>
                          )}
                        </StyledResetPaddingContainer>
                      </StyledWrapperTop>
                    </StyledHeaderContainer>
                    <StyledGridWrapper
                      item={true}
                      xs={12}
                      xl={12}
                      lg={12}
                      md={12}
                    >
                      <Grid container={true} spacing={0}>
                        <StyledTitleContainer
                          item={true}
                          xs={12}
                          sm={12}
                          xl={9}
                          lg={8}
                          md={12}
                        >
                          {this.state.items !== "" &&
                          this.state.items.parcelType === "Diamond" ? (
                            <StyledBrandName>
                              <ProductDescription
                                truncateLength={90}
                                data={this.state.items}
                                isTendor={false}
                              />
                            </StyledBrandName>
                          ) : (
                            <StyledBrandName>
                              <ProductDescription
                                truncateLength={90}
                                data={this.state.items}
                                isTendor={false}
                              />
                            </StyledBrandName>
                          )}
                        </StyledTitleContainer>
                        <StyledActionContainer
                          item={true}
                          xs={12}
                          sm={12}
                          xl={3}
                          lg={4}
                          md={12}
                        ></StyledActionContainer>
                      </Grid>
                    </StyledGridWrapper>
                    <Grid item={true} xs={12}>
                      <StyledContentWrapper container={true} spacing={0}>
                        <StyledProductCarousalColumn
                          item={true}
                          xs={12}
                          sm={6}
                          md={6}
                          lg={5}
                          xl={4}
                        >
                          {this.state.items.medias !== undefined &&
                          this.state.items.medias.length > 0 ? (
                            <DetailsCarousel {...this.state.items} t={t} />
                          ) : (
                            <StyledGrid container={true} spacing={0}>
                              <StyledNotAvailableImage>
                                <StyledProductImage src="/images/default-diamond.png"></StyledProductImage>
                              </StyledNotAvailableImage>
                            </StyledGrid>
                          )}
                        </StyledProductCarousalColumn>
                        <Grid item={true} xs={12} sm={6} md={6} lg={7} xl={8}>
                          <Grid item={true} xs={12}>
                            <StyledWrapperID item={true} xs={12}>
                              <StyledItemDesc item={true} xs={12}>
                                {t(
                                  "common:preview:productDetail:itemDescription"
                                )}
                                {": "}
                                {this.state.items !== "" &&
                                this.state.items.parcelType === "Diamond" ? (
                                  <StyledProductName>
                                    <ProductDescription
                                      truncateLength={90}
                                      data={this.state.items}
                                      isTendor={false}
                                    />
                                  </StyledProductName>
                                ) : (
                                  <StyledProductName>
                                    <ProductDescription
                                      truncateLength={90}
                                      data={this.state.items}
                                      isTendor={false}
                                    />
                                  </StyledProductName>
                                )}
                              </StyledItemDesc>
                              {this.state.fullData.subItems != null && (
                                <>
                                  {this.state.count === 0 ? (
                                    <StyledArrowIconsID
                                      onClick={this.previousButtonSubItem}
                                      src="/images/controls/left_arrow_round_edges_disabled.png"
                                    />
                                  ) : (
                                    <StyledArrowIconsID
                                      onClick={this.previousButtonSubItem}
                                      src="/images/controls/left_arrow_round_edges.png"
                                    />
                                  )}
                                  {this.state.fullData.subItems.length ===
                                  this.state.count ? (
                                    <StyledArrowIconsID
                                      onClick={this.nextButtonSubItem}
                                      src="/images/controls/right_arrow_round_edges_disabled.png"
                                    />
                                  ) : (
                                    <StyledArrowIconsID
                                      onClick={this.nextButtonSubItem}
                                      src="/images/controls/right_arrow_round_edges.png"
                                    />
                                  )}
                                </>
                              )}
                            </StyledWrapperID>
                            {this.state.items !== "" && (
                              <PerItemDescription
                                t={t}
                                {...this.state.items}
                                auctionDate={auctionDate}
                                isPDView={this.state.isPDView}
                                onClickSignIn={() =>
                                  this.onPressSign(
                                    this.state.fullData.auctionId,
                                    this.state.fullData.perId,
                                    auctionDate
                                  )
                                }
                              />
                            )}
                          </Grid>
                        </Grid>
                      </StyledContentWrapper>
                    </Grid>
                  </StyledLayoutGrid>
                ) : (
                  <StyledLayoutGrid container={true} spacing={0}>
                    <StyledHeaderContainer
                      item={true}
                      xs={12}
                      className="headerContainer"
                    >
                      <StyledWrapperTop container={true} spacing={0}>
                        {/* <StyledResetPaddingContainer
                              item
                              xs={3}
                              sm={6}
                              lg={3}
                            >
                              <StyledLogoWrapper>
                                <StyledLogoImage src="/images/logo.png" />
                                <StyledTextContainer>
                                  {t("common:header:logoText")}
                                </StyledTextContainer>
                              </StyledLogoWrapper>
                            </StyledResetPaddingContainer> */}
                      </StyledWrapperTop>
                    </StyledHeaderContainer>
                    <Grid container={true} spacing={0}>
                      <StyledOOPsContainer>
                        <StyledSorryTextWrapper>
                          {t("common:pageNotFound:oops")}
                        </StyledSorryTextWrapper>
                        <StyledTextWrapper>
                          {t(
                            "common:preview:perItemDescription:noProductMessage"
                          )}
                        </StyledTextWrapper>
                      </StyledOOPsContainer>
                    </Grid>
                  </StyledLayoutGrid>
                ),
              ]
            )}
          </Grid>
        )}
      </Grid>
    );
  }
}
