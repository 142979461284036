import React, { useEffect, useRef, useState } from "react";
import IRouterComponentProps from "../../models/IRouterComponentProps";
import Grid from "@material-ui/core/Grid";
import Header from "./Header/Header";
import Hero from "./Hero/Hero";
import Section from "./Section/Section";
import ProductList from "./ProductCard/ProductList";
import { IProductCardProps } from "./ProductCard/ProductCard";
import Colors from "../../common/materials/colors";
import DescriptionList from "./DescriptionCard/DescriptionList";
import Button from "../../common/elements/Button";
import {
  LandingPageOpenSansFont,
  LandingPagePlayFairFont,
} from "./StyledComponents";
import Footer from "./Footer/Footer";
import { DialogProvider } from "../../common/elements/Dialog/Dialog";
import { getAdverstisements, getEndedProducts, getProducts } from "./Services";
import { toggleLoginPopup } from "./Utils";
import MobileDescriptionList from "./DescriptionCard/MobileDescriptionList";
import { makeStyles, useMediaQuery } from "@material-ui/core";
import MobileMenu from "./Header/MobileMenu";
import { useTranslation } from "react-i18next";
import AdsList from "./Ads/AdsList";
import { useHistory } from "react-router";

const descriptionCards = [
  {
    description: `common:exploreAuctionsCards:1`,
    imageSrc:
      "https://ds29nc3ljdjce.cloudfront.net/explore-auctions1-transparent.png",
    redirect: true,
    link: "/zipang",
  },
  {
    description: `common:exploreAuctionsCards:2`,
    imageSrc:
      "https://ds29nc3ljdjce.cloudfront.net/explore-auctions2-transparent.png",
    link: "365products",
    redirect: false,
  },
  {
    description: `common:exploreAuctionsCards:3`,
    imageSrc:
      "https://ds29nc3ljdjce.cloudfront.net/explore-auctions-3-transparent.png",
    link: "preview",
    redirect: false,
  },
];

const useStyles = makeStyles({
  noPadding: {
    padding: "0",
  },
});

const LandingPageAdSource = "Landing_Page";
const LandingPage = (props: IRouterComponentProps) => {
  // const { t } = props;
  const isEng = localStorage.getItem("language") === "EN";
  const HowItWorkCards = [
    {
      imageSrc: isEng
        ? "https://ds29nc3ljdjce.cloudfront.net/gd_en.png"
        : "https://ds29nc3ljdjce.cloudfront.net/gd_jp.png",
      link: "/sell",
      redirect: true,
    },
    {
      imageSrc: isEng
        ? "https://ds29nc3ljdjce.cloudfront.net/gyaa_en.png"
        : "https://ds29nc3ljdjce.cloudfront.net/gyaa_jp.png",
      link: "auctioneer",
      redirect: true,
    },
    {
      imageSrc: isEng
        ? "https://ds29nc3ljdjce.cloudfront.net/sell_n_enjoy_en.png"
        : "https://ds29nc3ljdjce.cloudfront.net/sell_n_enjoy_jp.png",
      link: "/sell",
      redirect: true,
    },
  ];
  const howItWorksRef = useRef();
  const [liveProductsList, setLiveProductList] = useState(
    [] as IProductCardProps[]
  );
  const [previousAuctionList, setPreviousAcutctionsList] = useState(
    [] as IProductCardProps[]
  );

  const [adImageSources, setAdImageSources] = useState(
    [] as { link: string; imgSrc: string }[]
  );
  const classes = useStyles();

  const getProductsList = async () => {
    const list = await getProducts();
    const endedProducts = await getEndedProducts();
    if (!list?.results) {
      return;
    }
    if (list.results.length !== 0) {
      setLiveProductList(list.results.slice(0, 3) as IProductCardProps[]);
    }
    if (endedProducts.results.length !== 0) {
      setPreviousAcutctionsList(
        endedProducts.results.slice(0, 3) as IProductCardProps[]
      );
    }
  };

  const getAdvertisementsList = async () => {
    const { response } = await getAdverstisements();
    if (!response.length) {
      return;
    }
    const adSources = response
      .filter((ad: { section: string }) =>
        ad.section.includes(LandingPageAdSource)
      )
      .map((adSource: { media: { presignedUrl: any }; link: any }) => ({
        imgSrc: adSource.media.presignedUrl,
        link: adSource.link,
      })) as { link: string; imgSrc: string }[];
    const imageSources = adSources;
    setAdImageSources(imageSources);
  };

  useEffect(() => {
    localStorage.setItem("paymentConditionAgree", "false");
    localStorage.setItem("refreshFlag", "false");
    getProductsList();
    setLiveProductList([]);
  }, []);

  useEffect(() => {
    getAdvertisementsList();
  }, []);

  const history = useHistory();
  const isMobile = useMediaQuery("(max-width: 768px)");
  const { t } = useTranslation();

  const handleSellDiamonds = () => history.push("/sell");

  const handleHomePageRedirect = () => {
    toggleLoginPopup();
  };

  return (
    <DialogProvider>
      <div className="mainContainer">
        {isMobile ? null : <Header />}
        <LandingPageOpenSansFont>
          {isMobile ? <Header /> : null}
          <LandingPagePlayFairFont>
            <Hero isMobile={isMobile} inViewRef={howItWorksRef} />
          </LandingPagePlayFairFont>
          {isMobile && <MobileMenu />}
          {liveProductsList.length ? (
            <Section
              id="liveAuction"
              label={t("common:liveAuc")}
              pageLink="/auctionslist/live"
            >
              <ProductList
                disableMobileView
                isMobile={isMobile}
                ads={[]}
                productsList={liveProductsList}
                isZipang={false}
                isLandingPage={true}
              />
            </Section>
          ) : null}
          <Section
            ref={howItWorksRef}
            id="howItWorks"
            label={t("common:hiw")}
            color={Colors.lightGrey}
            isMobile={isMobile}
          >
            {isMobile ? (
              <MobileDescriptionList descriptionCards={HowItWorkCards} />
            ) : (
              <DescriptionList descriptionCards={HowItWorkCards} />
            )}
          </Section>
          <Section
            id="eaSection"
            label={t("common:header:buttons:ea")}
            isMobile={isMobile}
          >
            {isMobile ? (
              <MobileDescriptionList descriptionCards={descriptionCards} />
            ) : (
              <DescriptionList descriptionCards={descriptionCards} />
            )}
          </Section>
          <div style={{ marginBottom: "3em" }}>
            <Grid
              container
              justifyContent="center"
              alignContent="center"
              direction={isMobile ? "column" : "row"}
            >
              <Button
                isMobile={isMobile}
                wide
                widePadding
                type="contained-black"
                onClick={handleSellDiamonds}
              >
                {t("common:header:buttons:sellYDiamonds")}
              </Button>
              <Button
                isMobile={isMobile}
                wide
                widePadding
                type="contained-dark"
                onClick={handleHomePageRedirect}
              >
                {t("common:header:buttons:buyDiamonds")}
              </Button>
            </Grid>
            {isMobile ? <AdsList adsList={adImageSources} /> : null}
          </div>
          {previousAuctionList.length && !isMobile ? (
            <Section container>
              <Grid xs={9} item>
                <Section
                  className={classes.noPadding}
                  container
                  label={t("common:prevAuc")}
                  pageLink="/auctionslist/previous"
                >
                  <ProductList
                    hideBidButton
                    fixedHeightCards
                    isMobile={isMobile}
                    productsList={previousAuctionList}
                    ads={[]}
                    isZipang={false}
                    isLandingPage={true}
                  />
                </Section>
              </Grid>
              <Grid xs={3} item>
                <AdsList adsList={adImageSources} />
              </Grid>
            </Section>
          ) : null}
          {isMobile && previousAuctionList.length ? (
            <Section
              className={classes.noPadding}
              container
              label={t("common:prevAuc")}
              pageLink="/auctionslist/previous"
            >
              <ProductList
                hideBidButton
                isMobile
                productsList={previousAuctionList}
                ads={[]}
                isZipang={false}
                isLandingPage={true}
              />
            </Section>
          ) : null}
          <Footer />
        </LandingPageOpenSansFont>
      </div>
    </DialogProvider>
  );
};

export default LandingPage;
